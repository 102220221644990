var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { on: { "mouseover": () => _vm.showDetail = true, "mouseleave": () => _vm.showDetail = false } }, [_c(_vm.hasProfileLink ? "RouterLink" : "div", { tag: "component", staticClass: "flex info", class: {
    "items-center": _vm.hasShowDetail,
    "cursor-pointer": _vm.hasProfileLink,
    "w-44": !_vm.large,
    "w-full": _vm.large
  }, attrs: { "to": _vm.hasProfileLink ? _vm.employeeLink : null } }, [_c("div", { staticClass: "mr-2 flex" }, [_c("AppAvatar", _vm._b({ attrs: { "employee": _vm.employee } }, "AppAvatar", _vm.avatarAttributes, false))], 1), _c("div", { staticClass: "min-w-26 flex flex-col justify-center" }, [_c("div", { directives: [{ name: "tooltip", rawName: "v-tooltip", value: _vm.truncateName ? _vm.employee.full_name : null, expression: "truncateName ? employee.full_name : null" }], staticClass: "font-bold whitespace-nowrap block truncate max-w-full", class: { "text-[var(--link-color)]": _vm.hasProfileLink, "text-wgray-600": !_vm.hasProfileLink } }, [_vm._v(" " + _vm._s(_vm.fullNameTruncated) + " ")]), !_vm.hasShowDetail && (_vm.showWorkJob || _vm.showWorkerNumber) ? _c("div", { staticClass: "text-fs-14 text-grey3" }, [_vm.employee.work_puesto && _vm.showWorkJob ? _c("div", { staticClass: "work-puesto" }, [_vm._v(" " + _vm._s(_vm.employee.work_puesto) + " ")]) : _vm._e(), _vm.employee.work_employee_number && _vm.showWorkerNumber && (_vm.$is("hra") || _vm.$is("osr")) ? _c("div", { staticClass: "text-fs-12" }, [_vm._v(" Colaborador #" + _vm._s(_vm.employee.work_employee_number) + " ")]) : _vm._e(), _vm._t("moreInfo")], 2) : _vm._e()]), _vm.hasShowDetail && (_vm.showDetail || _vm.hoverRow) ? _c("div", [_c("span", { staticClass: "text-fs-14 p-2 border border-grey3 text-grey3 rounded whitespace-nowrap cursor-pointer btn-detail", on: { "click": () => _vm.$emit("showDetail") } }, [_vm._v(" Ver detalle ")])]) : _vm._e()]), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
